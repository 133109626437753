<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="dates"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        label="Intervallum"
        prepend-icon="mdi-calendar"
        solo
        color="red"
        background-color="grey lighten-2"
        item-color="red"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dates" range scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(dates)"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    modal: false,
    dates: []
  }),
  watch: {
    dates(val) {
      this.$emit("change", val);
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>