import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

import tasks from "./modules/tasks"
import stations from "./modules/stations"
import user from "./modules/user"
import modal from "./modules/modal"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tasks,
        stations,
        user,
        modal,
    },
    state: {
        loadingStatus: false,
    },
    mutations: {
        loadingStatus (state, newLoadingStatus) {
            state.loadingStatus = newLoadingStatus
        }
    },
    actions: {
        loadingStatus (context, value) {
            context.commit('loadingStatus', value)
        }
    },
    getters: {
        loadingStatus (state) {
            return state.loadingStatus
        }
    }
})
