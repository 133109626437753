<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
            fab 
            small 
            dark
            color="blue"
            v-bind="attrs"
            v-on="on"
        >
            <v-icon dark>mdi-information-variant</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{this.part.code}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                Termék: {{this.product.code}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Termék/DB: {{this.numberPerProduct}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Anyag: {{this.part.commodity.name}} ({{this.part.commodity.producer}})
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Komment: {{this.part.comment}}
              </v-col>
              <v-col cols="12" sm="12" md="12"  v-if="this.part.is_unique">
                Egyedi alkatrész
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Kép: <v-img :src="'/storage/' + this.part.image" v-show="hasImage" max-width="200" class="white"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Bezárás</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    props: ['product', 'part'],

    data: () => ({
      dialog: false,
    }),

    computed: {
        numberPerProduct() {
            return this.product.parts.find(x => x.id == this.part.id).pivot.parts_per_product
        },

        hasImage(){
          if(this.part.image == null || this.part.image == "") return false
          else return true
        }

    }
  }
</script>