<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
            fab 
            small 
            dark
            color="blue"
            v-bind="attrs"
            v-on="on"
        >
            <v-icon dark>mdi-information-variant</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{this.product.code}} - {{this.product.name}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                Kategória: {{this.product.category.name}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Vágási fájl: {{this.product.cutting_file}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Guide: {{this.product.packing_file}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Kinek: {{this.product.customer}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Komment: {{this.product.comment}}
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div v-show="!edit" @click="edit = true">
                  <label>Raktár hely: {{ this.product.depo_location }}</label>
                </div>
                <v-text-field 
                  v-show="edit" 
                  v-model="product.depo_location"
                  label = "Raktár Hely"
                  background-color = "grey lighten-3"
                  color="red" 
                  v-on:blur="updateLocation"
                  @keyup.enter="updateLocation"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12"  v-if="this.product.is_unique">
                Egyedi termék
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Bezárás</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    props: ['product'],

    data: () => ({
      dialog: false,
      edit: false,
    }),

    methods: {
      updateLocation(){
        if(this.edit){
          this.$store.dispatch('loadingStatus', true)

          axios.post('/product/depo-location',  {
            id: this.product.id,
            depo_location: this.product.depo_location
          })
            .then(response => {
              this.$store.dispatch('loadingStatus', false)
              this.edit = false
            })
            .catch(error => {
              alert('Nem sikerült elmenteni')
              this.errors = error.response.data.errors
              this.$store.dispatch('loadingStatus', false)
              this.edit = false;
            });
        }
      }
    }
  }
</script>