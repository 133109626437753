<template>
<v-col cols="12">
  <v-card>
    <v-card-title>
      Befejezett Feladatok
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="tasks"
      :search="search"
    >
      <template v-slot:item.updated_at="{ item }">
        <span>{{new Date(item.updated_at).toLocaleString()}}</span>
      </template>
    </v-data-table>
  </v-card>
</v-col>
</template>
<script>
  export default {
    data () {
        return {
            search: '',
            headers: [
                { text: 'id', value: 'id' },
                { text: 'Termék', value: 'product.code' },
                { text: 'status', value: 'status' },
                { text: 'sets', value: 'sets' },
                { text: 'packed', value: 'packed' },
                { text: 'updated_at', value: 'updated_at' },
            ],
        }
    },

    props: ['tasks']

  }
</script>