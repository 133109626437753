/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vuetify from "../plugins/vuetify"

import router from "./router"

import store from "./store"

import excel from 'vue-excel-export'

Vue.use(excel)
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('app', require('./components/App.vue').default);

Vue.component('autocomplete', require('./components/partials/Autocomplete.vue').default);
Vue.component('combobox', require('./components/partials/Combobox.vue').default);
Vue.component('loader', require('./components/partials/Loader.vue').default);
Vue.component('image-dialog', require('./components/partials/ImageDialog.vue').default);

Vue.component('depo-table', require('./components/depo/DepoTable.vue').default);
Vue.component('depo-table-row', require('./components/depo/DepoTableRow.vue').default);
Vue.component('depo', require('./components/depo/Depo.vue').default);
Vue.component('depo-tabs', require('./components/depo/DepoTabs.vue').default);
Vue.component('depo-tracked-parts', require('./components/depo/DepoTrackedParts.vue').default);

Vue.component('new-product', require('./components/new_product/NewProduct.vue').default);
Vue.component('new-product-table', require('./components/new_product/NewProductTable.vue').default);
Vue.component('new-parts-table', require('./components/new_product/NewPartsTable.vue').default);
Vue.component('recipe-dialog', require('./components/new_product/RecipeDialog.vue').default);
Vue.component('new-recipe-dialog', require('./components/new_product/NewRecipeDialog.vue').default);

Vue.component('planner', require('./components/planner/Planner.vue').default);
Vue.component('add-jobs', require('./components/planner/AddJobs.vue').default);
Vue.component('alert-task-table', require('./components/planner/AlertTaskTable.vue').default);
Vue.component('add-jobs-table', require('./components/planner/AddJobsTable.vue').default);
Vue.component('priority-table', require('./components/planner/PriorityTable.vue').default);
Vue.component('tasks-table', require('./components/planner/TasksTable.vue').default);
Vue.component('task-history-table', require('./components/planner/TaskHistoryTable.vue').default);
Vue.component('task-history-row', require('./components/planner/TaskHistoryRow.vue').default);

Vue.component('overview', require('./components/overview/Overview.vue').default);
Vue.component('station-card', require('./components/overview/StationCard.vue').default);
Vue.component('task-card', require('./components/overview/TaskCard.vue').default);
Vue.component('completed-task-card', require('./components/overview/CompletedTaskCard.vue').default);

Vue.component('sum', require('./components/summary/Sum.vue').default);
Vue.component('summary-station-card', require('./components/summary/StationCard.vue').default);
Vue.component('summary-task-card', require('./components/summary/TaskCard.vue').default);

Vue.component('stations', require('./components/stations/Stations.vue').default);
Vue.component('task-table', require('./components/stations/TaskTable.vue').default);
Vue.component('subtask-row', require('./components/stations/SubtaskRow.vue').default);
Vue.component('last-station-task-table', require('./components/stations/LastStationTaskTable.vue').default);
Vue.component('last-station-subtask-row', require('./components/stations/LastStationSubtaskRow.vue').default);
Vue.component('part-info-dialog', require('./components/stations/PartInfoDialog.vue').default);
Vue.component('product-info-dialog', require('./components/stations/ProductInfoDialog.vue').default);
Vue.component('other-button-dialog', require('./components/stations/OtherButtonDialog.vue').default);
Vue.component('message-dialog', require('./components/stations/MessageDialog.vue').default);

Vue.component('unique', require('./components/unique/Unique.vue').default);
Vue.component('unique-product-table', require('./components/unique/UniqueProductTable.vue').default);
Vue.component('unique-parts-table', require('./components/unique/UniquePartsTable.vue').default);

Vue.component('image-upload', require('./components/image/ImageUpload.vue').default);
Vue.component('image-upload-form', require('./components/image/ImageUploadForm.vue').default);

Vue.component('stats', require('./components/stats/Stats.vue').default);
Vue.component('date-picker', require('./components/stats/DatePicker.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    vuetify: Vuetify,
    router,
    store,
    el: '#app',
});
