<template>
  <v-card>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row justify="center">
        <v-col>
          <autocomplete
            @change="onChangePart"
            label="Alkatrészkód?"
            url="part/autocomplete"
            item-text="code"
            :rules="[(v) => !!v || 'Field is required']"
          ></autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            v-model="minimum"
            type="number"
            label="Minimum érték"
            solo
            color="red"
            background-color="grey lighten-2"
            :rules="[(v) => !!v || 'Field is required']"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn class="mx-2 my-2" dark color="green" @click="updatePart"
            >Hozzáad</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-card-title>
      <v-row>
        <v-col> Figyelt Alkatrészek </v-col>
        <v-col>
          <v-select
            v-model="type"
            :items="items"
            solo
            color="red"
            background-color="grey lighten-2"
            item-color="red"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table :headers="headers" :items="filteredParts" :search="search">
      <template v-slot:item.image="{ item }">
        <span>
          <v-icon
            v-show="item.image != null"
            @click="show(item.image)"
            >mdi-image</v-icon
          >
        </span>
      </template>
      <template v-slot:item.free="{ item }">
        <span>{{ item.current_stock - item.reserved }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.current_stock - item.reserved >= item.min_quantity">
          <v-icon large color="green darken-2"> mdi-battery </v-icon>
        </span>
        <span v-else-if="item.current_stock > 0">
          <v-icon large color="yellow darken-2"> mdi-battery-30 </v-icon>
        </span>
        <span v-else>
          <v-icon large color="red darken-2">
            mdi-battery-alert-variant-outline
          </v-icon>
        </span>
      </template>
    </v-data-table>
    <image-dialog :dialog="showImage" :image="image" @close="showImage=false"/>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Kód", value: "code" },
        { text: "Kép", value: "image" },
        { text: "Komment", value: "comment" },
        { text: "Raktáron", value: "current_stock" },
        { text: "Lefoglalva", value: "reserved" },
        { text: "Szabad", value: "free", sortable: false },
        { text: "Minimum", value: "min_quantity" },
        { text: "Állapot", value: "status", sortable: false },
      ],
      parts: [],
      type: "Mindegyik",
      items: ["Mindegyik", "Hiányos"],
      part: null,
      minimum: null,
      valid: false,
      showImage: false,
      image: ""
    };
  },

  mounted() {
    this.$store.dispatch("loadingStatus", true);

    axios
      .get("/part/tracked")
      .then((response) => {
        this.parts = response.data;
        this.$store.dispatch("loadingStatus", false);
      })
      .catch((error) => {
        this.errors = error.response.data.errors;
        this.$store.dispatch("loadingStatus", false);
      });
  },

  computed: {
    filteredParts() {
      if (this.type == "Mindegyik") return this.parts;
      else
        return this.parts.filter(
          (part) => part.current_stock - part.reserved <= part.min_quantity
        );
    },
  },

  methods: {
    onChangePart(val) {
      this.part = val;
    },
    updatePart() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("loadingStatus", true);

        axios
          .post("/part/tracked", {
            id: this.part.id,
            minimum: this.minimum,
          })
          .then((response) => {
            this.minimum = null;
            this.parts = response.data;
            this.$store.dispatch("loadingStatus", false);
            alert("Sikeresen hozzáadva!");
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            this.$store.dispatch("loadingStatus", false);
          });
      }
    },
    show(image){
      this.image = image
      this.showImage = true
    }
  },
};
</script>