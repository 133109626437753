<template>

        <v-simple-table >
            <tbody>
                <tr class="grey lighten-3">
                    <td class="text-center" >
                        <v-btn icon @click="show = !show">
                            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn> 
                    </td>
                    <td class="text-center" >
                    {{task.product.code}}
                    </td>
                    <td class="text-center" >
                    {{task.product.name}}
                    </td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "task.sets"
                            label = "SZETT"
                            placeholder="0"
                            background-color = "white"
                            color="red" 
                            type = "number"
                            :rules="rules"
                        ></v-text-field>
                    </td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "task.min_sets"
                            label = "MIN"
                            placeholder="0"
                            background-color = "white"
                            color="red" 
                            type = "number"
                            :rules="minRules"
                        ></v-text-field>
                    </td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "task.comment"
                            label = "KOMMENT"
                            background-color = "white"
                            color="red" 
                            type = "text"
                        ></v-text-field>
                    </td>
                    <td class="text-center" > </td>
                    <td class="text-center" >
                        <v-btn color="red" @click='deleteClicked'>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn> 
                    </td>
                </tr>
                <tr class="white" v-show="show" >
                    <td class="text-center">
                        KÓD
                    </td>
                    <td class="text-center">
                        DB/SZETT
                    </td>
                    <td class="text-center" >
                        TERMÉKHEZ KELL
                    </td>
                    <td class="text-center" >
                        GYÁRTANI
                    </td>
                    <td class="text-center" >
                        MINIMUM GYÁRTANI
                    </td>
                    <td class="text-center" >
                        RAKTÁRBÓL
                    </td>
                    <td class="text-center" >
                        RAKTÁRON(FOGLALT)
                    </td>
                    <td class="text-center" >
                        KOMMENT
                    </td>
                </tr>
                <tr class="white" v-show="show" v-for="subtask in task.subtasks" :key="subtask.part.id">
                    <td class="text-center">
                    {{subtask.part.code}}
                    </td>
                    <td class="text-center">
                    {{ subtask.part.pivot.parts_per_product}}
                    </td>
                    <td class="text-center" >{{subtask.needed}}</td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "subtask.pieces"
                            label = "DB"
                            background-color = "grey lighten-3"
                            color="red" 
                            type = "number"
                            :rules="rules"
                        ></v-text-field>
                    </td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "subtask.min_pieces"
                            label = "MIN"
                            placeholder="0"
                            background-color = "grey lighten-3"
                            color="red" 
                            type = "number"
                            :rules="rules"
                        ></v-text-field>
                    </td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "subtask.from_storage_pieces"
                            label = "RAKTÁRBÓL"
                            background-color = "grey lighten-3"
                            color="red" 
                            type = "number"
                            :rules="rules"
                        ></v-text-field>
                    </td>
                    <td class="text-center" >
                    {{subtask.part.current_stock}}({{subtask.part.reserved}})
                    </td>
                    <td class="text-center" >
                        <v-text-field  
                            v-model = "subtask.comment"
                            label = "KOMMENT"
                            background-color = "grey lighten-3"
                            color="red" 
                        ></v-text-field>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
</template>
<script> 
    export default {

        data: () => ({
            color: 'red',
            label: 'Sablon',
            show: true,
            rules: [
                v => !!v || 'required',
                v => (v >= 0) || 'negative',
            ],
            minRules: [
                v => !!v || 'required',
                v => (v >= 0) || 'negative'
            ],
        }),

        props: ['index', 'task'],

        mounted() {
            this.calculate()
        },

        watch: {
            'task.sets'(val) {
                this.calculate()
            },

            'task.min_sets'(val) {
                this.calculate()
            },
        },

        methods: {
            deleteClicked() {
                this.$emit("delete", this.index)
            },

            calculate() {
                this.task.subtasks.forEach(subtask => {
                    subtask.needed = (subtask.part.pivot.parts_per_product * this.task.sets).toString()
                    var min_needed = (subtask.part.pivot.parts_per_product * this.task.min_sets).toString()
            
                    var ready =  (subtask.part.current_stock - subtask.part.reserved).toString()
                    if(ready < 0) ready = 0

                    subtask.from_storage_pieces = ((+subtask.needed  > +ready) ? ready.toString() : subtask.needed.toString() )
                    subtask.pieces = ((+subtask.needed  > +ready) ? (subtask.needed  - ready).toString() : "0")
                    subtask.min_pieces = ((+min_needed > +ready) ? (min_needed - ready).toString() : "0") 
                    if(+subtask.pieces > 0 && subtask.min_pieces == "0") subtask.min_pieces = "1" 
                })  
            },

        },
  }
</script>