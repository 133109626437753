<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="grey lighten-2"
      slider-color="red"
      color="red"
      align-with-title
    >
      <v-tab> Raktár </v-tab>
      <v-tab> Figyelt Alkatrészek </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <depo/>
      </v-tab-item>
      <v-tab-item>
        <depo-tracked-parts/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      tab: null,
    };
  },
};
</script>