<template>
    <v-col cols="12" sm="6" md="4" lg="2">
        <v-card :color="station.color">
            <v-card-title>
                {{ station.name }}
            </v-card-title>
            <v-card-text>
                <task-card v-for="task in taskOnStation" :key="task.id" :task="task" :station="station"></task-card> 
            </v-card-text>
        </v-card>
    </v-col>
</template>
<script>
    export default {

        data: () => ({
        }),

        props: ['station', 'tasks'],

        computed: {
            taskOnStation: function() {
                return this.tasks.filter(task => task.subtasks.filter(subtask => subtask.current_station_id == this.station.id).length > 0)
            } 
        }
    }


</script>