<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red"
          v-bind="attrs"
          v-on="on"
        >
          EGYÉB
        </v-btn>
      </template>
        <v-card>
            <v-card-title>
                <span class="headline">Részfeladat átrakása más allomásra</span>
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col-3"><b>ID:</b> #{{this.subtask.id}}</div>
                    <div class="col-3"><b>KÓD:</b> {{this.subtask.part.code}}</div>
                    <div class="col-3"><b>DB(MIN):</b> {{this.subtask.pieces}}({{this.subtask.min_pieces}})</div>
                    <div class="col-3"><b>INPUT DB:</b> {{this.subtask.current_pieces}}</div>
                </div>
                <div class="row">
                    <div class="col-12"><b>JELENLEGI ÁLLOMÁS:</b> {{this.currentStationName}}</div>
                </div>
                <v-form v-model="valid" ref="form" lazy-validation>
                    <div class="row">
                            <div class="col-6">
                                <v-select
                                    v-model="station_id"
                                    :items="this.subtask.part.recipe.stations"
                                    item-text="name"
                                    item-value="id"
                                    label="ÁLLOMÁSRA KÜLDÉS"
                                    color="red"
                                    :rules="[v => !!v || 'Station is required']"
                                ></v-select>
                            </div>
                            <div class="col-6">
                                <v-text-field
                                    v-model="input"
                                    label="INPUT DB"
                                    color="red"
                                    :rules="[v => v>=0 || 'Input is negative']"
                                ></v-text-field>
                            </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <v-text-field
                                v-model="comment"
                                label="Komment"
                                color="red"
                            ></v-text-field>
                        </div>
                    </div> 
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialog = false">MÉGSE</v-btn>
                <v-btn color="green darken-1" text @click="save">KÜLDÉS</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        dialog: false,
        station_id: null,
        input: 0,
        comment: "",
        valid: false,
      }
    },

    props: ['subtask'],

    mounted() {
        this.input = this.subtask.current_pieces
        this.comment = this.subtask.comment
    },

    methods: {
      save() {
        if(this.$refs.form.validate()){
            if(this.subtask.current_station_id == this.station) alert("Nem lehetséges, mert már ezen az állomáson van!")
            else {
                 this.$store.dispatch('loadingStatus', true)

                axios.post('/subtask/other',  {
                    station_id: this.station_id,
                    subtask_id: this.subtask.id,
                    input: this.input,
                    comment: this.comment
                })
                .then(response => {
                    this.subtask.current_pieces = response.data.current_pieces
                    this.subtask.comment = response.data.comment
                    this.subtask.current_station_id = response.data.current_station_id
                    this.$store.dispatch('loadingStatus', false)
                    this.dialog = false
                    alert('Feladat sikeresen átrakva!')
                })
                .catch(error => {
                    alert(error.response.data)
                    this.$store.dispatch('loadingStatus', false)
                });
            }
        }
      }
    },

    computed: {   
        currentStationName() {
            return this.subtask.part.recipe.stations.find(x => x.id === this.subtask.current_station_id).name
        },
    }
  }
</script>