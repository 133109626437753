import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Planner from "../components/planner/Planner"
import DepoTabs from "../components/depo/DepoTabs"
import NewProduct from "../components/new_product/NewProduct"
import Overview from "../components/overview/Overview"
import Sum from "../components/summary/Sum"
import Stations from "../components/stations/Stations"
import Unique from "../components/unique/Unique"
import Image from "../components/image/ImageUpload"
import Stats from "../components/stats/Stats"


const routes = [
    {
        component: Planner,
        name: "planner",
        path: "/planner"
    },

    {
        component: DepoTabs,
        name: "depo",
        path: "/depo"
    },

    {
        component: NewProduct,
        name: "new-product",
        path: "/new-product"
    },

    {
        component: Overview,
        name: "overview",
        path: "/overview"
    },

    {
        component: Stations,
        name: "stations",
        path: "/station"
    },

    {
        component: Unique,
        name: "unique",
        path: "/unique"
    },

    {
        component: Image,
        name: "image",
        path: "/image"
    },

    {
        component: Sum,
        name: "sum",
        path: "/summary"
    },

    {
        component: Stats,
        name: "stats",
        path: "/stats"
    },
];

export default new VueRouter({
    routes
});