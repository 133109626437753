<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation>
    <v-container>
      <v-row justify="center" align="start" no-gutters>
        <v-col class="col-12" >
          <div class="red" v-for="error in errors">{{ error[0] }}</div>
          <v-card >
            <unique-product-table
            :product = 'product'
            :productCategories = 'productCategories'
            ></unique-product-table>        
          </v-card>         
        </v-col>
      </v-row>
      <v-row justify="end" no-gutters>
        <v-col class="col-12">
          <v-card>
            <unique-parts-table 
            v-for="(part, index) in parts" 
            :key="part.key" 
            :index="`${index}`"  
            @delete='deletePart'
            @recipe='openRecipeDialog'
            :part = 'part'
            :commodities='commodities'
            :recipeTemplates='recipeTemplates'
            >{{`#${index}`}}</unique-parts-table>        
          </v-card>  
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col class="col-1">
          <v-btn class="mx-2"  dark color="green" @click="addPart">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-1">
            <v-text-field  
              v-model="sets"
              label = "DB"
              placeholder="1"
              color="red" 
              type = "number"
              :rules="[v => !!v || 'DB is required']"
            ></v-text-field>
        </v-col>
        <v-col class="col-1">
            <v-text-field  
              v-model="min_sets"
              label = "MIN DB"
              placeholder="0"
              color="red" 
              type = "number"
              :rules="[v => !!v || 'MIN DB is required']"
            ></v-text-field>
        </v-col>
        <v-col class="col-2">
          <v-text-field  
              v-model = 'comment'
              label = "KOMMENT"
              placeholder="A kis darabok is.."
              background-color = "white"
              color="red" 
            ></v-text-field>
        </v-col>
        <v-col class="col-1">
          <v-checkbox v-model="isPriority" label="TOP PRIO"></v-checkbox>
        </v-col>
        <v-col class="col-1">
          <v-btn class="mx-2"  dark color="blue" @click="onSave" >
            MENTÉS
          </v-btn>
        </v-col>
      </v-row>
      <recipe-dialog 
        :showRecipeDialog = 'showRecipeDialog' 
        :partToEdit='partToEdit' 
        @change="changeRecipeDialog" >
      </recipe-dialog>
    </v-container>  
  </v-form>
</template>


<script> 
class Part {
    constructor(stations) {
      this.id = null
      this.code = null
      this.comment = null
      this.status = "ACTIVE"
      this.commodity_id = null
      this.current_stock = 0
      this.reserved = 0
      this.recipe_id = null
      this.image = null
      this.pieces_per_sets = 1
      this.key = (new Date()).getTime()
      this.stations_not_used = stations.slice()
      this.stations_used = []
      this.is_unique_recipe = false
      this.is_existing = false
    }
}

class Product {
    constructor() {
      this.id = null
      this.code = null
      this.name = null
      this.customer = null
      this.comment = null
      this.product_category_id = null
      this.status = "ACTIVE"
      this.cutting_file = null
      this.packing_file = null
    }
}

  export default {
    data: () => ({
      product: new Product,
      parts: [],
      commodities: null,
      recipeTemplates: null,
      productCategories: null,
      productCategory: null,
      stations: null,
      showRecipeDialog: false,
      partToEdit: null,
      valid: false,
      sets: null,
      min_sets:null,
      isPriority: false,
      comment: null,
      errors: {}
    }),

    async mounted() {

      this.$store.dispatch('loadingStatus', true)
      
      await axios
        .get('/product-category')
        .then(response => (this.productCategories = response.data))

      await axios
        .get('/commodity')
        .then(response => (this.commodities = response.data))

      await axios
        .get('/recipe/templates')
        .then(response => (this.recipeTemplates = response.data))

      await axios
        .get('/station')
        .then(response => (this.stations = response.data))

      this.$store.dispatch('loadingStatus', false)
 
    },

    methods: {
      addPart () {
        this.parts.push(new Part(this.stations))
      },

      deletePart(index) {
        this.$delete(this.parts, index)
      },

      openRecipeDialog(index) {
        this.partToEdit = this.parts[index]
        this.showRecipeDialog = true
      },

      changeRecipeDialog(val) {
        this.showRecipeDialog = val
        this.$refs.form.validate()
      },

      onSave () {
          if(this.$refs.form.validate()){

            this.$store.dispatch('loadingStatus', true)

            axios.post('/unique/save',  {
              product: this.product,
              parts: this.parts,
              isPriority: this.isPriority,
              sets: this.sets,
              min_sets: this.min_sets,
              comment: this.comment
            })
              .then(response => {
                  this.$store.dispatch('loadingStatus', false)
                  alert('Egyedi termék létrehozva és ütemezve!')
                  window.location.reload()

              })
              .catch(error => {
                this.errors = error.response.data.errors
                this.$store.dispatch('loadingStatus', false)
              });
          }
      },
    },
  }
</script>