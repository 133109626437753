const state = {
    user: {},
};
const getters = {
    user: state => {
        return state.user
    },
};
const actions = {
    getUser({commit}) {
        axios.get('/user')
            .then(res => {
                {
                    commit('getUser', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
};
const mutations = {
    getUser(state, user) {
        state.user = user
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}