<template>
  <v-simple-table>
    <tbody>
      <tr @click="show = !show" class="grey">
        <td>#{{this.subtask.id}}</td>
        <td>{{this.subtask.part.code}}</td>
        <td>{{this.subtask.current_station.name}}</td>
        <td>Kér: {{this.subtask.pieces}}</td>
        <td>Min: {{this.subtask.min_pieces}}</td>
        <td>Rak: {{this.subtask.from_storage_pieces}}</td>
        <td>DB: {{this.subtask.current_pieces}}</td>
        <td>
          <v-tooltip right v-if="this.subtask.comment">
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                v-bind="attrs"
                v-on="on"
              >mdi-comment</v-icon>
            </template>
            <span>{{this.subtask.comment}}</span>
          </v-tooltip>
        </td>
        <td>Mar:{{this.subtask.left}}</td>
      </tr>
      <task-history-row v-for="history in subtask.histories" :key="history.id" :history="history" v-show="show"/>
    </tbody>
  </v-simple-table>
</template>
<script>
export default {
  props: ["subtask"],

  data() {
    return {
      show: false,
    };
  },
};
</script>