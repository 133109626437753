<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-row justify="center">
      <v-col cols="3">
        <autocomplete
          @change="onChangeProduct"
          label="Termékkód?"
          url="/product/autocomplete"
          item-text="code"
        ></autocomplete>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="sets"
          label="DARAB"
          background-color="white"
          color="red"
          type="number"
          :rules="rules"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="minSets"
          label="Minimum"
          background-color="white"
          color="red"
          type="number"
          :rules="minRules"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="addProduct"> Hozzáad </v-btn>
      </v-col>
    </v-row>
    <v-alert
      v-model="showAlertModal"
      border="left"
      prominent
      type="warning"
      dismissible
      outlined
    >
      <h3>Figyelem! Már gyártásban lévő termék!</h3>
      <alert-task-table
        v-for="task in tasksInProduction"
        :key="task.id"
        :task="task"
      />
    </v-alert>
  </v-form>
</template>
<script>
import AlertTaskTable from "./AlertTaskTable.vue";
export default {
  components: { AlertTaskTable },
  data() {
    return {
      isVisible: false,
      valid: true,
      product: null,
      parts: null,
      sets: null,
      minSets: 1,
      rules: [(v) => !!v || "required", (v) => (v && v >= 0) || "negative"],
      minRules: [
        (v) => !!v || "required",
        (v) => (v && v >= 0) || "negative",
        (v) => +v <= +this.sets || "Bigger then sets",
      ],
      showAlertModal: false,
      tasksInProduction: [],
    };
  },

  watch: {
    sets(val) {
      this.$refs.form.validate();
    },
  },

  methods: {
    async onChangeProduct(val) {
      if (val) {
        this.$store.dispatch("loadingStatus", true);

        this.product = val;
        this.showAlertModal = false;

        await axios
          .get("/product/" + val.id + "/parts")
          .then((response) => {
            this.parts = response.data;
          })
          .catch((error) => {
            console.log(error);
          });

        await axios
          .post("/task/production/details/product", {
            id: this.product.id,
          })
          .then((response) => {
            this.tasksInProduction = response.data;
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        if (this.tasksInProduction.length) this.showAlertModal = true;

        this.$store.dispatch("loadingStatus", false);
      }
      else {
        this.product = null;
        this.parts = null;
        this.showAlertModal = false;
        this.tasksInProduction = [];
      }
    },

    addProduct() {
      if (this.$refs.form.validate()) {
        this.$emit("add", this.product, this.sets, this.minSets, this.parts);
      }
    },
  },
};
</script>
