<template>
  <div>
    <v-row class="justify-center mt-10">
      <v-col cols="3">
        <date-picker :dates="dates" @change="onChangeDates" />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="type"
          :items="items"
          solo
          color="red"
          background-color="grey lighten-2"
          item-color="red"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <autocomplete
          @change="onChangeProduct"
          label="Termékkód?"
          url="product/autocomplete"
          item-text="code"
        />
      </v-col>
      <v-col cols="1">
        <v-btn class="mx-2 my-2" dark color="blue" @click="search"
          >KERESÉS</v-btn
        >
      </v-col>
      <v-col cols="1">
        <export-excel
          class="btn btn-default"
          :data="tasks"
          :fields="fields"
          worksheet="My Worksheet"
          name="prox.xls"
        >
          <v-btn v-show="show" class="mx-2 my-2" dark color="green">LETÖLTÉS</v-btn>
        </export-excel>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="10">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Code</th>
                <th class="text-left">Sets</th>
                <th class="text-left">Packed</th>
                <th class="text-left">Created</th>
                <th class="text-left">Last Updated</th>
                <th class="text-left">Started</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tasks" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.sets }}</td>
                <td>{{ item.packed }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.updated_at }}</td>
                <td>{{ item.started_at }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DatePicker from "./DatePicker.vue";
export default {
  components: { DatePicker },
  data: () => ({
    dates: [],
    show: false,
    product: null,
    valid: false,
    checkbox: false,
    type: "Befejezve",
    items: ["Befejezve", "Kiadva"],
    fields: {
      id: "id",
      code: "code",
      sets: "sets",
      packed: "packed",
      created: "created_at",
      last_updated: "updated_at",
      started: "started_at",
    },
    tasks: null,
  }),
  methods: {
    onChangeProduct(val) {
      if(val) this.product = val.id;
      else this.product = null;
    },
    onChangeDates(val) {
      this.dates = val;
    },
    async search() {
      if (this.dates.length > 1) {
        this.$store.dispatch("loadingStatus", true);

        await axios
          .get("/stats", {
            params: {
              date1: this.dates[0],
              date2: this.dates[1],
              type: this.type,
              product: this.product,
            },
          })
          .then((response) => {
            this.tasks = response.data;
            this.show = true
          })
          .catch((err) => {
            console.log(err);
          });

        this.$store.dispatch("loadingStatus", false);
      } else {
        alert("Válasszon idő intervallumot!");
      }
    },
  },
};
</script>