<template>
  <tr>
    <td>#{{ this.history.id }}</td>
    <td>Mennyi: {{ this.history.pieces }}</td>
    <td v-if="history.send_from_station">Honnan: {{ this.history.send_from_station.name }}</td>
    <td v-else>Honnan: -</td>
    <td v-if="history.send_to_station">Hova: {{ this.history.send_to_station.name }}</td>
    <td v-else>Hova: -</td>
    <td v-if="history.user">Ki: {{ this.history.user.name }}</td>
    <td v-else>Ki: -</td>
    <td>{{ new Date(this.history.created_at).toLocaleString() }}</td>
  </tr>
</template>
<script>
export default {
  props: ["history"],

  data() {
    return {
      show: false,
    };
  },

  computed: {},

  methods: {},
};
</script>