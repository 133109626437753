<template>
  <v-simple-table>
    <tbody>
      <tr class="grey lighten-2">
        <td class="text-center" width="100px"><h5>ÚJ TERMÉK</h5></td>
        <td class="text-center">
          <combobox
            label="TERMÉKÓD"
            url="/product/autocomplete"
            item-text="code"
            @selected="onSelected"
          ></combobox>
        </td>
        <td class="text-center">
          <v-text-field
            v-model="product.name"
            label="TERMÉKNÉV"
            placeholder="Gloombox"
            background-color="white"
            color="red"
            :rules="[(v) => !!v || 'Name is required']"
            required
          ></v-text-field>
        </td>
        <td class="text-center" width="150">
          <v-select
            v-model="product.product_category_id"
            label="KATEGÓRIA"
            :items="productCategories"
            item-text="name"
            item-value="id"
            background-color="white"
            color="red"
            :rules="[(v) => !!v || 'Catgeroy is required']"
            required
          ></v-select>
        </td>
        <td class="text-center">
          <v-text-field
            v-model="product.cutting_file"
            label="VÁGÁSI FÁJL"
            placeholder="L\GYÁRTÁS\INSERTS\LGB"
            background-color="white"
            color="red"
          ></v-text-field>
        </td>
        <td class="text-center">
          <v-text-field
            v-model="product.packing_file"
            label="GUIDE"
            placeholder="L\GUIDES\INSERTS\LGB"
            background-color="white"
            color="red"
          ></v-text-field>
        </td>
        <td class="text-center">
          <v-text-field
            v-model="product.customer"
            label="MEGRENDELŐ"
            placeholder="LASEROX"
            background-color="white"
            color="red"
            :rules="[(v) => !!v || 'Customer is required']"
          ></v-text-field>
        </td>
        <td class="text-center">
          <v-text-field
            v-model="product.comment"
            label="KOMMENT"
            placeholder="2=3 ugyanaz"
            background-color="white"
            color="red"
          ></v-text-field>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
export default {
  props: ["productCategories", "product"],

  data: () => ({}),

  methods: {
    onSelected(val) {
      if (typeof val === "string") {
        this.product.code = val;
      } else {
        this.product.code = val.code;
      }
    },
  },
};
</script>