<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <autocomplete
          @change="onChangePart"
          label="Alkatrészkód?"
          url="part/autocomplete"
          item-text="code"
        ></autocomplete>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <image-upload-form
          :part="this.part"
          v-show="isVisible"
        ></image-upload-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      part: "",
      isVisible: false,
    };
  },

  methods: {
    onChangePart(val) {
      if (val) {
        this.$store.dispatch("loadingStatus", true);

        setTimeout(() => {
          axios.get("/part/" + val.id).then((response) => {
            this.part = response.data;
            this.isVisible = true;
          });

          this.$store.dispatch("loadingStatus", false);
        }, 500);
      }
      else {
        this.part = "";
        this.isVisible = false;
      }
    },
  },
};
</script>