<template>
    <div class="mx-1" >
        <v-card :color="station.color" width="205">
            <v-card-title class="justify-center">
                <span v-html="station.icon"/>
            </v-card-title>
            <v-card-text class="px-1">
                <summary-task-card v-for="task in taskOnStation" :key="task.id" :task="task" :station="station"/>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    export default {

        data: () => ({
        }),

        props: ['station', 'tasks'],

        computed: {
            taskOnStation: function() {
                return this.tasks.filter(task => task.subtasks.filter(subtask => subtask.current_station_id == this.station.id).length > 0)
            } 
        }
    }


</script>