const state = {
    tasksCompleted: [],
    tasksInProduction: [],
    tasksInProductionWithDetails: [],
    allTasks: [],
};
const getters = {
    tasksCompleted: state => {
        return state.tasksCompleted
    },
    tasksInProduction: state => {
        return state.tasksInProduction
    },
    tasksInProductionWithDetails: state => {
        return state.tasksInProductionWithDetails
    },
    allTasks: state => {
        return state.allTasks
    }
};
const actions = {
    fetchTasksInProduction({commit}) {
        axios.get('/task/production')
            .then(res => {
                {
                    commit('fetchTasksInProduction', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    fetchTasksInProductionWithDetails({commit}) {
        axios.get('/task/production/details')
            .then(res => {
                {
                    commit('fetchTasksInProductionWithDetails', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    fetchTasksCompleted({commit}) {
        axios.get('/task/completed')
            .then(res => {
                {
                    commit('fetchTasksCompleted', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    fetchAllTasks({commit}) {
        axios.get('/task')
            .then(res => {
                {
                    commit('fetchAllTasks', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
};
const mutations = {
    fetchTasksInProduction(state, tasks) {
        state.tasksInProduction = tasks
    },
    fetchTasksInProductionWithDetails(state, tasks) {
        state.tasksInProductionWithDetails = tasks
    },
    fetchTasksCompleted(state, tasks) {
        state.tasksCompleted = tasks
    },
    fetchAllTasks(state, tasks) {
        state.allTasks = tasks
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}