<template>
    <v-form
        v-model="valid"
        ref="form"
        lazy-validation>
        <v-card color="red">
            <v-simple-table color="grey lighten-2">
                <thead class="grey lighten-2">
                    <tr>
                        <th class="text-center">Kód</th>
                        <th class="text-center">Meglévő Kép</th>
                        <th class="text-center">Komment</th>
                        <th class="text-center">Új Kép feltöltés</th>
                        <th class="text-center">Mentés</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">{{part.code}}</td>
                        <td class="text-center">
                        <v-icon 
                            v-show="this.part.image!=null"  
                            @click="showImage = !showImage"
                        >mdi-image</v-icon>
                        </td>
                        <td class="text-center">{{part.comment}}</td>
                        <td class="text-center">
                            <v-file-input
                                v-model="image"
                                color="red"
                                truncate-length="30"
                                label="Kép"
                                accept="image/png, image/jpeg, image/bmp"
                                show-size
                                :rules="rules"
                            ></v-file-input>
                        </td>
                        <td class="text-center">
                            <v-btn color="blue" @click="onSave()" small><v-icon dark>mdi-content-save</v-icon></v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
        <image-dialog :dialog="showImage" :image="part.image" @close="showImage=false"></image-dialog>
    </v-form>
</template>
<script>
    export default {
        data () {
            return {
                showImage: false,
                image: null,
                valid: false,
                rules: [
                    value => !!value || 'Image is required!',
                    value => !value || value.size < 1000000 || 'Maximális méret 1 MB!',
                ],
            }
        },

        props: ["part"],

        methods: {
            onSave () {
                if(this.$refs.form.validate()){

                    this.$store.dispatch('loadingStatus', true)

                    let formData = new FormData();

                    formData.append("image", this.image);

                    axios.post("/part/" + this.part.id + "/image", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        this.part.image = response.data.image
                        this.image = null
                        alert('Kép sikeresen feltöltve!')
                        this.$store.dispatch('loadingStatus', false)
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors
                        this.$store.dispatch('loadingStatus', false)
                    });

                }
            }
        }

    }
</script>