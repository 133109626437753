<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="4">
        <v-select
          v-model="stationId"
          :items="stations"
          item-text="name"
          item-value="id"
          label="Állomás"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" v-if="isLastStation">
        <last-station-task-table
          v-for="task in taskOnStation"
          :key="task.id"
          :task="task"
          :station="station"
          v-show="show"
        ></last-station-task-table>
      </v-col>
      <v-col cols="12" v-else>
        <task-table
          v-for="task in taskOnStation"
          :key="task.id"
          :task="task"
          :station="station"
          v-show="show"
        ></task-table>
      </v-col>
    </v-row>
    <message-dialog/>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    stationId: null,
    show: false,
  }),

  mounted() {
    this.$store.dispatch("stations/fetchUserStations");
    this.$store.dispatch("tasks/fetchTasksInProductionWithDetails");

    window.Echo.channel("task-channel").listen(
      "UpdateTasksInProduction",
      (e) => {
        console.log(e);
        this.$store.dispatch("tasks/fetchTasksInProductionWithDetails");
      }
    );
  },

  computed: {
    tasks() {
      return this.$store.getters["tasks/tasksInProductionWithDetails"];
    },
    taskOnStation() {
      if (this.stationId)
        return this.tasks.filter(
          (task) =>
            task.subtasks.filter(
              (subtask) => subtask.current_station_id == this.stationId
            ).length > 0
        );
      return [];
    },
    stations() {
      return this.$store.getters["stations/userStations"];
    },
    station() {
      return this.stations.find((x) => x.id == this.stationId);
    },
    isLastStation() {
      if (this.station) return this.station.is_last;
      return false;
    },
  },

  watch: {
    stationId(val) {
      if (val) this.show = true;
      else this.show = false;
    },
  },
};
</script>