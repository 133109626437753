<template>
    <tr :class="color">
        <image-dialog :dialog="showImage" :image="part.image" @close="showImage=false"></image-dialog>
        <td class="text-center">{{ part.code }}</td>
        <td class="text-center">
          <v-icon 
            v-show="partHasImage"  
            @click="showImage = !showImage"
          >mdi-image</v-icon>
        </td>
        <td class="text-center">{{ part.current_stock }} ({{ part.reserved }})</td>
        <td class="text-center"  width="100px"><v-text-field v-model='diff' :loading='isLoading' type="number" @keyup.enter='onButtonClick(part)' background-color = "grey lighten-2" color="red"></v-text-field></td>
        <td class="text-center"><v-btn color="blue" @click='onButtonClick(part)' small><v-icon dark>mdi-content-save</v-icon></v-btn></td>
    </tr>
</template>
<script>
    export default {

        data () {
            return {
                diff: 0,
                isLoading: false,
                result: null,
                color: '',
                showImage: false
            }
        },

        props: {
            part: {
                type: Object
            }
        },

        computed: {
            partHasImage(){
                if(this.part.image == null || this.part.image == "") return false
                else return true
            }
        },

        methods: {
            onButtonClick(val) {

                this.isLoading = true;

                axios.post('/part/update', {id: val.id, diff: this.diff})
                .then(response => { 
                    this.part.current_stock = response.data.current_stock;
                    this.part.reserved = response.data.reserved;
                    this.diff = 0
                    this.color = 'table-success'
                 })
                .catch(error => alert('Csak szám használható!'))

                this.isLoading = false

                setTimeout(() => {
                    this.color = '';
                }, 2000);
            }
        },

    }
</script>