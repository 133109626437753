<template>
  <v-card>
    <v-row>
      <station-card v-for="station in stations" :key="station.id" :station="station" :tasks="tasks"></station-card>
    </v-row>
    <v-row>
      <completed-task-card :tasks="completedTasks"></completed-task-card>
    </v-row>  
  </v-card>
</template>


<script>
  export default {

    mounted() {
      this.$store.dispatch('tasks/fetchTasksInProduction')
      this.$store.dispatch('tasks/fetchTasksCompleted')
      this.$store.dispatch('stations/fetchStations')
      
      window.Echo.channel('task-channel')
        .listen('UpdateTasksInProduction', (e) => {
          console.log(e)
          this.$store.dispatch('tasks/fetchTasksInProduction')
        })
        .listen('UpdateTasksCompleted', (e) => {
          console.log(e)
          this.$store.dispatch('tasks/fetchTasksCompleted')
        });
    },

    computed: {
      tasks() {
        return this.$store.getters['tasks/tasksInProduction'];
      },
      completedTasks() {
        return this.$store.getters['tasks/tasksCompleted'];
      },
      stations() {
        return this.$store.getters['stations/stations'];
      }
    }

  }
</script>