const state = {
    modalVisible: false,
    response: {},
};
const getters = {
    modalVisible: state => {
        return state.modalVisible
    },
    response: state => {
        return state.response
    },
};
const actions = {
    showModal(context, response) {
        context.commit('showModal', response)
    },
    hideModal(context) {
        context.commit('hideModal')
    },
};
const mutations = {
    showModal(state, response) {
        state.modalVisible = true
        state.response = response
    },
    hideModal(state) {
        state.modalVisible = false
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}