<template>
    <v-container>
        <v-row justify="center" >
            <v-col cols="4" >
                <autocomplete @change="onChangeProduct" label="Termékkód?" url="product/autocomplete" item-text="code" :rules="[v => !!v || 'Field is required']"></autocomplete>
            </v-col>
            <v-col cols="4" >
                <autocomplete @change="onChangePart" label="Alkatrészkód?" url="part/autocomplete" item-text="code" :rules="[v => !!v || 'Field is required']"></autocomplete>
            </v-col>
        </v-row>
        <v-row justify="center" >
            <v-col cols="6">
                <depo-table :parts="this.parts" v-show="isVisible" :product="this.product">{{title}}</depo-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {

        data () {
            return {
                title: '',
                parts: null,
                product: null,
                isVisible: false
            }
        },

        watch: {
            title (val) {
              if(val == null) this.isVisible = false
            },
        },

        methods: {
            onChangeProduct(val) {
                this.title = val.code
                this.product = val

                this.$store.dispatch('loadingStatus', true)
              
                setTimeout(() => {
                    axios.get('/product/' + val.id + '/parts').then(response => {
                        this.parts = response.data;
                        this.isVisible = true
                    });

                    this.$store.dispatch('loadingStatus', false)
                }, 500)
            },

            onChangePart(val) {
                this.title = val.code
                this.product = null

                this.$store.dispatch('loadingStatus', true)
              
                setTimeout(() => {
                    axios.get('/part/' + val.id).then(response => {
                        this.parts = []
                        this.parts.push(response.data)
                        this.isVisible = true
                    });

                    this.$store.dispatch('loadingStatus', false)
                }, 500)
            },
        }
    }
</script>