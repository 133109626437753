<template>
  <div class="text-center">
    <v-dialog v-model="dialog" @click:outside="hide"  @keydown.esc="hide" width="500">
      <v-card :color="color">
        <v-card-title>
          {{ this.message }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="hide"> Bezárás </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  computed: {
    response() {
      return this.$store.getters["modal/response"];
    },
    dialog() {
      return this.$store.getters["modal/modalVisible"];
    },
    message: function () {
      if ("success" in this.response) return this.response.success;
      if ("error" in this.response) return this.response.error;
      return "";
    },
    color: function () {
      if ("success" in this.response) return "green lighten-2";
      if ("error" in this.response) return "red lighten-2";
      return "white";
    },
  },

  methods: {
    hide() {
        return this.$store.dispatch("modal/hideModal")
    },
  },
};
</script>