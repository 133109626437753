<template>
    <v-combobox
         v-model="select"
        :loading="loading"
        :items="items"
        :item-text="itemText"
        :search-input.sync="search"
        cache-items
        hide-no-data
        :label='label'
        background-color = "white"
        color="red" 
        :rules="[v => !!v || 'Field is required']"
    ></v-combobox>
</template>

<script>
    export default{

            data () {
                return {
                    loading: false,
                    items: [],
                    search: null,
                    select: null,
                }
            },

            props: [ 'label', 'url', 'itemText'],

            watch: {
                search (val) {
                    val && val !== this.select && this.querySelections(val)
                },
                select (val) {
                    this.$emit('selected', val)
                }
            },

            methods: {
                querySelections (v) {
                    this.loading = true
                
                    setTimeout(() => {
                        axios.get(this.url,{params: {query: v}}).then(response => {
                            this.items = response.data;
                        });

                        this.loading = false
                    }, 500)
                },
            }
        }
</script>