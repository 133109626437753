<template>
  <v-row justify="center">
    <v-dialog v-model="show" width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Recept összeállítás?</span>
        </v-card-title>
        <v-card-text>
        <div class="row">
            <div class="col-md-6">
              <v-simple-table>
                <thead>
                  <tr>
                      <th class="text-left">Állomások</th>
                  </tr>
                </thead>
                <draggable :list="list1" group="recept" tag="tbody">
                    <tr v-for="(element, index) in list1" :key="element.name">
                        <td>{{ element.name }}</td>
                    </tr>
                </draggable>
              </v-simple-table>
            </div>
            <div class="col-md-6">
                <v-simple-table>
                  <thead>
                    <tr>
                        <th class="text-left">Recept</th>
                    </tr>
                  </thead>
                  <draggable :list="list2" group="recept" tag="tbody">
                      <tr v-for="(element, index) in list2" :key="element.name">
                          <td>{{ element.name }}</td>
                      </tr>
                  </draggable>
                </v-simple-table>
              </div>
            </div>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">Mentés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  import draggable from "vuedraggable";
  export default {
    components: {
      draggable
    },

    data () {
      return {
        show: this.showRecipeDialog,
        list1: [],
        list2: []
      }
    },

    props: ['showRecipeDialog', 'partToEdit'],

    watch: {
      showRecipeDialog(val) {
        this.show = val
      },

      show(val) {
        this.$emit("change", val)
      },

      'partToEdit.key'() {
        this.list1 = this.partToEdit.stations_not_used
        this.list2 = this.partToEdit.stations_used
      }
    },

    methods: {
      close() {
        if(!this.partToEdit.stations_used[this.partToEdit.stations_used.length - 1].is_last) alert("Nem jó állomás van az utolsó helyen!")
        else this.show = false
      }
    }
  }
</script>