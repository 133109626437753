<template>
    <v-card :color="color" elevation="20" class="ma-2 pa-2">
        <div class="d-flex justify-space-between">
            <div><b>#{{task.id}}</b></div> 
            <div>
                <v-icon 
                    color="blue"
                    v-show="this.task.product.is_unique"  
                >mdi-alpha-e-box-outline</v-icon>          
                <v-icon 
                    color="green"
                    v-show="isReady"  
                >mdi-check</v-icon>
            </div> 
            <div><b>{{code}}</b></div>
        </div>
        <div class="d-flex justify-space-between">
            <div>DB: {{task.sets}}</div>
            <div>PR: {{task.priority}}</div> 
            <div>{{numberOfSubtasks}}/{{numberOfAllSubtasks}}</div>
        </div>
    </v-card>
</template>
<script>
    export default {

        data: () => ({
        }),

        props: ['task','station'],

        computed: {
            color() {
                if(this.task.status == "INACTIVE") return "grey"
                else return "white";
            },

            numberOfAllSubtasks() {
                return this.task.subtasks.length
            },

            numberOfSubtasks() {
                return this.task.subtasks.filter(subtask => subtask.current_station_id == this.station.id).length
            },

            isReady() {
                if( this.station.is_last && this.numberOfSubtasks == this.numberOfAllSubtasks) return true
                else return false
            },

            code(){
                if(this.task.product.is_unique) return this.task.product.code.split('#')[0]
                return this.task.product.code
            },

        }
    }

</script>