<template>
    <v-simple-table >
        <tbody>
            <tr class="purple lighten-5">
                <td class="text-center" width="30px">
                   <slot></slot>
                </td>
                <td class="text-center" width="180">
                    <combobox label="KÓD" url="/part/autocomplete" item-text="code" @selected="onSelected"></combobox>
                </td>
                <td class="text-center" width="120">
                    <v-select
                        v-model = "part.commodity_id"
                        label="ANYAG"
                        :items = 'commodities'
                        item-text = "name"
                        item-value = "id"
                        background-color = "white"
                        color="red"
                        :disabled="part.is_existing"
                        :rules="[v => !!v || 'Commodity is required']"
                    ></v-select>
                </td>
                <td class="text-center" width="100px">
                    <v-text-field  
                        v-model= "part.pieces_per_sets"
                        label = "DB/TERMÉK"
                        placeholder="1"
                        background-color = "white"
                        color="red" 
                        type = "number"
                        :rules="[v => !!v || 'DB/T is required']"
                    ></v-text-field>
                </td>
                <td class="text-center" width="320">
                    <v-text-field  
                        v-model="part.comment"
                        label = "KOMMENT"
                        placeholder="2=3 ugyanaz"
                        background-color = "white"
                        color="red" 
                        :disabled="part.is_existing"
                    ></v-text-field>
                </td>
                <td class="text-center">
                    <v-switch v-model="part.is_unique_recipe" :label="label" color="red" :rules = "[ uniqueRecipeRules ]" :disabled = "part.is_existing"></v-switch>
                </td>
                <td class="text-center" width="100px"  v-show = '!part.is_unique_recipe'>
                    <v-select
                        v-model = "part.recipe_id"
                        label="RECEPT"
                        :items = 'recipeTemplates'
                        item-text = "template_name"
                        item-value = "id"
                        background-color = "white"
                        color="red" 
                        :disabled = "part.is_existing"
                        :rules = "[ recipeRules ]"
                    ></v-select>
                </td>
                <td class="text-center" width="100px" v-show = 'part.is_unique_recipe'>
                    <v-btn :color="color" :disabled="part.is_existing" @click='recipeClicked'>
                        <v-icon>mdi-clipboard-edit</v-icon>
                    </v-btn> 
                </td>
                <!--<td class="text-center" >
                    <v-file-input 
                    v-model = "part.image"
                    accept="image/*" 
                    label="Kép"
                    ></v-file-input> 
                </td>-->
                <td class="text-center" >
                    <v-btn color="red" @click='deleteClicked'>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn> 
                </td>
            </tr>
        </tbody>
    </v-simple-table>      
</template>
<script> 
    export default {

        data: () => ({
            color: 'red',
            label: 'Sablon'
        }),

        props: ['index', 'part', 'commodities', 'recipeTemplates'],

        watch: {
            'part.stations_used'(val) {
                if(val.length) this.color = 'green'
                else this.color = 'red'
            },

            'part.is_unique_recipe'(val) {
                if(val){
                    this.label = 'Egyedi'
                }
                else{
                    this.label = 'Sablon'
                    //this.part.recipe_id = null
                } 
            }
        },

        computed: {
            ppsColor() {
                if(this.part.is_existing) return "red lighten-4"
                else return "white"
            },
        },

        methods: {
            deleteClicked() {
                this.$emit("delete", this.index)
            },

            recipeClicked() {
                this.$emit("recipe", this.index)
            },

            onSelected(val) {
                if(typeof(val) === "string"){
                    this.part.code = val
                    this.part.is_existing = false
                } 
                else {
                    this.part.code = val.code
                    this.part.id = val.id
                    this.part.comment = val.comment
                    this.part.status = val.status
                    this.part.commodity_id = val.commodity_id
                    this.part.current_stock = val.current_stock
                    this.part.reserved = val.reserved
                    this.part.recipe_id = val.recipe_id
                    this.part.image = val.image
                    this.part.pieces_per_sets = val.pieces_per_sets
                    this.part.is_existing = true
                }
            },

            recipeRules(value) {
                if(!value && !this.part.is_unique_recipe){
                    return "Recipe is required"
                }

                return true;
            },

            uniqueRecipeRules(value) {
                if(!this.part.stations_used.length && this.part.is_unique_recipe){
                    return "Unique recipe is required"
                }

                return true;
            }

        },
  }
</script>