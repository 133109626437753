<template>
        <v-simple-table>
            <tbody>
                <tr :class="color" @click="pressed">
                    <td class="text-left" width="80px">
                        <v-btn icon v-show="active">
                            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn> 
                    </td>
                    <td class="text-left" width="80px">#{{task.id}}</td>
                    <td class="text-left" width="80px">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon 
                            color="blue" 
                            v-show="task.product.is_unique"
                            v-bind="attrs"
                            v-on="on"
                          >mdi-alpha-e-box-outline</v-icon>
                        </template>
                        <span>Egyedi termék</span>
                      </v-tooltip>
                    </td>
                    <td class="text-left" width="50px"></td>
                    <td class="text-left" width="50px"></td>
                    <td class="text-left" width="150px">{{code}}</td>
                    <td class="text-left" width="200px">{{task.comment}}</td>
                    <td class="text-left" width="100px">{{task.sets}}/{{task.min_sets}}</td>
                    <td class="text-left" width="80px"></td>
                    <td class="text-left" width="100px">PRIO: {{task.priority}}</td>
                    <td class="text-left" width="100px">
                      <v-btn color="green" v-show="!active" @click="activateTask">
                        ELFOGAD
                      </v-btn> 
                    </td>
                    <td class="text-left" width="50px"><product-info-dialog :product="task.product"></product-info-dialog></td>
                </tr>
                <tr class="white" v-show="show" >
                    <td class="text-left" width="80px">ID</td>
                    <td class="text-left" width="80px">KÓD</td>
                    <td class="text-left" width="50px">KÉP</td>
                    <td class="text-left" width="50px">KOM.</td>
                    <td class="text-left" width="80px">ANYAG</td>
                    <td class="text-left" width="150px">KOMMENT</td>
                    <td class="text-left" width="200px">IN/OUT</td>
                    <td class="text-left" width="100px">DB(MIN)</td>
                    <td class="text-left" width="80px">INPUT</td>
                    <td class="text-left" width="100px">TOVÁBB</td>
                    <td class="text-left" width="100px">EGYÉB</td>
                    <td class="text-left" width="50px"></td>
                </tr>
                <template v-if="show">
                  <subtask-row 
                      v-for="subtask in task.subtasks" 
                      :key="subtask.part.id" 
                      :subtask="subtask"
                      :station="station"
                      :product="task.product" 
                      @showImage="onShowImage"
                  ></subtask-row>
                </template>
            </tbody>
        </v-simple-table>
</template>


<script>
  export default {

    props: ['task', 'station'],

    data: () => ({
        show: false,
        showImage: false,
        image: ''
    }),

    computed: {
      color: function() {
        if(this.task.status == "INACTIVE") return "grey lighten-1"
        return "yellow lighten-1"
      },

      active: function() {
        if(this.task.status == "INACTIVE") return false
        return true
      },

      code(){
        if(this.task.product.is_unique) return this.task.product.code.split('#')[0]
        return this.task.product.code
      }
    },

    
    methods: {
      activateTask() {
        this.$store.dispatch('loadingStatus', true)

            axios.post('/task/status',  {
              id: this.task.id,
              status: "ACTIVE"
            })
                .then(response => {
                    this.task.status = "ACTIVE"
                    this.$store.dispatch('loadingStatus', false)
                    alert('Feladat elfogadva!')
                })
                .catch(error => {
                    this.$store.dispatch('loadingStatus', false)
                });
      },

      pressed(){
        if(this.active) this.show = !this.show
      },

      onShowImage(value){
        this.image = value,
        this.showImage = true
      }
      
    },
  }
</script>