<template>
    <v-row>
        <div class="px-4">ID: #{{ this.task.id }}</div>
        <div class="px-4"> Kód: {{ this.task.product.code }}</div>
        <div class="px-4">Státusz: {{ this.task.status }}</div>
        <div class="px-4">Kiadva: {{ new Date(this.task.created_at).toLocaleString() }}</div>
    </v-row>
</template>
<script>
export default {
    props: ['task'],
}
</script>
