<template>
    <v-card color="red">
        <v-card-title>
            <slot></slot>
            <v-spacer></v-spacer>
            <span v-if="this.product">
                <div v-show="!edit" @click="edit = true">
                    <label>Raktár hely: {{ this.product.depo_location }}</label>
                </div>
                <v-text-field 
                    v-show="edit" 
                    v-model="product.depo_location"
                    label = "Raktár Hely"
                    background-color = "grey lighten-3"
                    color="red" 
                    v-on:blur="updateLocation"
                    @keyup.enter="updateLocation"
                    autofocus
                ></v-text-field>
            </span>
        </v-card-title>
        <v-simple-table color="grey lighten-2">
            <thead>
                <tr>
                    <th class="text-center">Kód</th>
                    <th class="text-center">Kép</th>
                    <th class="text-center">Darabszám(Foglalt)</th>
                    <th class="text-center">Változás(+/-)</th>
                    <th class="text-center">Frissít</th>
                </tr>
            </thead>
            <tbody>
                <depo-table-row v-for="part in parts" :key="part.code" :part='part'></depo-table-row>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
    export default {

        props: {
            parts: {
                type: Array
            },
            product: {
                type: Object
            }
        },

        data: () => ({
            edit: false,
        }),

        methods: {
            updateLocation(){
                if(this.edit){
                    this.$store.dispatch('loadingStatus', true)

                    axios.post('/product/depo-location',  {
                        id: this.product.id,
                        depo_location: this.product.depo_location
                    })
                    .then(response => {
                        this.$store.dispatch('loadingStatus', false)
                        this.edit = false
                    })
                    .catch(error => {
                        alert('Nem sikerült elmenteni')
                        this.errors = error.response.data.errors
                        this.$store.dispatch('loadingStatus', false)
                        this.edit = false;
                    });
                }
            }
        }
    }
</script>