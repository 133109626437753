<template>
    <v-autocomplete
        v-model="select"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        :item-text="itemText"
        return-object
        cache-items
        hide-no-data
        :label='label'
        solo
        color="red"
        background-color="grey lighten-2"
        item-color="red"  
        appendIcon="mdi-magnify"   
        clearable     
        :rules="rules"  
        >
    </v-autocomplete>
</template>
<script>
    export default{

        data () {
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
            }
        },

        props: [ 'label', 'url', 'itemText', 'rules'],

        watch: {
            search (val) {
              val && val !== this.select && this.querySelections(val)
            },

            select (val) {
                this.$emit('change', val)
            }
        },

        methods: {
            querySelections (v) {
                this.loading = true
              
                setTimeout(() => {
                    axios.get(this.url,{params: {query: v}}).then(response => {
                        this.items = response.data;
                    });

                    this.loading = false
                }, 500)
            },
        }
    }
</script>
