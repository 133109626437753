<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-simple-table>
      <tbody>
        <tr :class="color" @click="pressed">
          <td class="text-left" width="80px">
            <v-btn icon v-show="active">
              <v-icon>{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </td>
          <td class="text-left" width="80px">#{{ task.id }}</td>
          <td class="text-left" width="50px">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="blue"
                  v-show="task.product.is_unique"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-alpha-e-box-outline</v-icon
                >
              </template>
              <span>Egyedi termék</span>
            </v-tooltip>
          </td>
          <td class="text-left" width="50px"></td>
          <td class="text-left" width="50px"></td>
          <td class="text-left" width="80px"></td>
          <td class="text-left" width="150px">{{ code }}</td>
          <td class="text-left" width="200px">{{ task.comment }}</td>
          <td class="text-left" width="100px">
            {{ task.sets }}/{{ task.min_sets }}
          </td>
          <td class="text-left" width="50px">Hely:</td>
          <td class="text-left" width="80px">
            {{ task.product.depo_location }}
          </td>
          <td class="text-left" width="80px">PRIO: {{ task.priority }}</td>
          <td class="text-left" width="100px">
            <v-btn color="green" v-show="!active" @click="activateTask">
              ELFOGAD
            </v-btn>
          </td>
          <td class="text-left" width="50px">
            <product-info-dialog :product="task.product"></product-info-dialog>
          </td>
        </tr>
        <tr class="white" v-show="show">
          <td class="text-left" width="80px">ID</td>
          <td class="text-left" width="80px">KÓD</td>
          <td class="text-left" width="50px">KÉP</td>
          <td class="text-left" width="50px">KOM.</td>
          <td class="text-left" width="50px">DB/T</td>
          <td class="text-left" width="80px">ANYAG</td>
          <td class="text-left" width="150px">KOMMENT</td>
          <td class="text-left" width="200px">IN/OUT</td>
          <td class="text-left" width="100px">DB(MIN)</td>
          <td class="text-left" width="50px">BEJÖTT</td>
          <td class="text-left" width="80px">RAK.(FOG.)</td>
          <td class="text-left" width="80px">MARADT</td>
          <td class="text-left" width="100px">EGYÉB</td>
          <td class="text-left" width="50px"></td>
        </tr>
        <template v-if="show">
          <last-station-subtask-row
            v-for="subtask in task.subtasks"
            :key="subtask.part.id"
            :subtask="subtask"
            :station="station"
            :product="task.product"
            :packed="task.packed"
          ></last-station-subtask-row>
          <tr v-show="show && ready">
            <td class="text-left" width="80px"></td>
            <td class="text-left" width="80px"></td>
            <td class="text-left" width="50px"></td>
            <td class="text-left" width="50px"></td>
            <td class="text-left" width="50px"></td>
            <td class="text-left" width="80px"></td>
            <td class="text-left" width="150px"></td>
            <td class="text-left" width="200px"></td>
            <td class="text-left" width="100px"></td>
            <td class="text-left" width="50px"></td>
            <td class="text-left" width="80px"></td>
            <td class="text-left" width="80px">
              <v-text-field
                v-model="task.packed"
                label="LECSOMAGOLT"
                background-color="grey lighten-3"
                color="red"
                type="number"
                :rules="rules"
                v-show="ready"
              ></v-text-field>
            </td>
            <td class="text-left" width="100px">
              <v-btn color="green" v-show="ready" @click="completeTask">
                MENTÉS
              </v-btn>
            </td>
            <td class="text-left" width="50px"></td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </v-form>
</template>
<script>
export default {
  props: ["task", "station"],

  data: () => ({
    show: false,
    rules: [(v) => !!v || "required", (v) => (v && v >= 0) || "negative"],
    valid: false,
  }),

  computed: {
    color: function () {
      if (this.task.status == "INACTIVE") return "grey lighten-1";
      if (this.ready) return "yellow lighten-1";
      return "purple lighten-2";
    },

    active: function () {
      if (this.task.status == "INACTIVE") return false;
      return true;
    },

    code() {
      if (this.task.product.is_unique)
        return this.task.product.code.split("#")[0];
      return this.task.product.code;
    },

    ready() {
      if (!this.active) return false;
      return this.task.subtasks.every(
        (subtask) => subtask.current_station_id == this.station.id
      );
    },

    maxSets() {
      if (!this.ready) return 0;

      var max_sets = 0;

      this.task.subtasks.forEach((subtask) => {
        var sets =
          (subtask.current_pieces + subtask.from_storage_pieces) /
          this.task.product.parts.find((x) => x.id == subtask.part.id).pivot
            .parts_per_product;
        if (max_sets == 0) max_sets = sets;
        else if (max_sets > sets) max_sets = sets;
      });

      return max_sets;
    },
  },

  methods: {
    activateTask() {
      this.$store.dispatch("loadingStatus", true);

      axios
        .post("/task/status", {
          id: this.task.id,
          status: "ACTIVE",
        })
        .then((response) => {
          this.task.status = "ACTIVE";
          this.$store.dispatch("loadingStatus", false);
          alert("Feladat elfogadva!");
        })
        .catch((error) => {
          this.$store.dispatch("loadingStatus", false);
        });
    },

    completeTask() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("loadingStatus", true);

        axios
          .post("/task/complete", {
            task: this.task,
          })
          .then((response) => {
            this.task.status = "COMPLETE";
            this.$store.dispatch("modal/showModal", response.data);
            this.$store.dispatch("loadingStatus", false);
          })
          .catch((error) => {
            alert(error.response.data.message);
            this.$store.dispatch("loadingStatus", false);
          });
      }
    },

    pressed() {
      if (this.active) this.show = !this.show;
    },
  },
};
</script>