<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="yellow darken-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          +RECEPT
        </v-btn>
      </template>

        <v-card>
            <v-card-title>
                <span class="headline">Új Recept összeállítása</span>
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <div class="col-12">
                            <v-text-field
                                v-model="name"
                                label="Recept név"
                                color="red"
                                :rules="rules"
                                required
                            ></v-text-field>
                        </div>
                    </v-form>
                </div>
                <div class="row">
            <div class="col-md-6">
              <v-simple-table>
                <thead>
                  <tr>
                      <th class="text-left">Állomások</th>
                  </tr>
                </thead>
                <draggable :list="list1" group="recept" tag="tbody">
                    <tr v-for="(element, index) in list1" :key="element.name">
                        <td>{{ element.name }}</td>
                    </tr>
                </draggable>
              </v-simple-table>
            </div>
            <div class="col-md-6">
                <v-simple-table>
                  <thead>
                    <tr>
                        <th class="text-left">Recept</th>
                    </tr>
                  </thead>
                  <draggable :list="list2" group="recept" tag="tbody">
                      <tr v-for="(element, index) in list2" :key="element.name">
                          <td>{{ element.name }}</td>
                      </tr>
                  </draggable>
                </v-simple-table>
              </div>
            </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialog = false">Mégse</v-btn>
                <v-btn color="green darken-1" text @click="save">Mentés</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import draggable from "vuedraggable";
  export default {
    components: {
      draggable
    },

    data () {
      return {
        dialog: false,
        list1: [],
        list2: [],
        valid: false,
        rules: [
            v => !!v || 'Name is required',
        ],
        name: "",
      }
    },

    props: ['stations'],

    watch: {
      dialog(val) {
        if(val){
            this.list1 = JSON.parse(JSON.stringify(this.stations))
            this.list2 = []
        }
      },
    },



    methods: {
      save() {
        if(this.$refs.form.validate()){
            if(this.list2.length == 0) alert("Recept üres!")
            else if(!this.list2[this.list2.length - 1].is_last) alert("Nem jó állomás van az utolsó helyen!")
            else {
                this.$store.dispatch('loadingStatus', true)

                axios.post('/recipe/new',  {
                stations: this.list2,
                name: this.name
                })
                .then(response => {
                    this.$store.dispatch('loadingStatus', false)
                    alert('Recept sikeresen létre lett hozva!')
                    window.location.reload()
                })
                .catch(error => {
                    this.$store.dispatch('loadingStatus', false)
                    alert(error.response.data.message)
                });
            }
        }
      }
    }
  }
</script>