<template>
    <tr :class="color">
        <image-dialog :dialog="showImage" :image="subtask.part.image" @close="showImage=false"></image-dialog>
        <td class="text-left" width="80px">#{{subtask.id}}</td>
        <td class="text-left" width="80px">{{code}}</td>
        <td class="text-left" width="50px">
          <v-icon 
            v-show="partHasImage"  
            @click="showImage = !showImage"
          >mdi-image</v-icon>
        </td>
        <td class="text-left" width="50px">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                v-show="partHasComment"  
                v-bind="attrs"
                v-on="on"
              >mdi-comment</v-icon>
            </template>
            <span>{{subtask.part.comment}}</span>
          </v-tooltip>
          <v-tooltip right>
           <template v-slot:activator="{ on, attrs }">
             <v-icon 
               color="blue" 
               v-show="subtask.part.is_unique"
               v-bind="attrs"
               v-on="on"
             >mdi-alpha-e-box-outline</v-icon>
            </template>
            <span>Egyedi alkatrész</span>
          </v-tooltip>
        </td>
        <td class="text-left" width="80px">{{subtask.part.commodity.name}}</td>
        <td class="text-left" width="150px">
          <v-text-field  
            v-model = "subtask.comment"
            background-color = "grey lighten-3"
            color="red" 
            v-show="isHere"
          ></v-text-field>
        </td>
        <td class="text-left" width="200px" v-if="isHere">{{previousStation}}/{{nextStation}}</td>
        <td class="text-left" width="200px" v-else>{{this.subtask.part.recipe.stations.find(x => x.id === this.subtask.current_station_id).name}}</td>
        <td class="text-left" width="100px">{{subtask.pieces}}({{subtask.min_pieces}})</td>
        <td class="text-left" width="80px">
            <v-text-field  
                v-model = "subtask.current_pieces"
                label = "ENNYI"
                background-color = "grey lighten-3"
                color="red" 
                type = "number"
                :rules="rules"
                v-show="isHere"
            ></v-text-field>
        </td>
        <td class="text-left" width="100px">
          <v-btn color="green" v-show="isHere" @click="giveToNextStation" :disabled="disabled">
            TOVÁBB
          </v-btn> 
        </td>
        <td class="text-left" width="100px">
         <other-button-dialog v-show="isHere" :subtask="subtask"></other-button-dialog>
        </td>
        <td><part-info-dialog :part="this.subtask.part" :product="product"></part-info-dialog></td>
    </tr>
</template>


<script>
  export default {

    props: ['subtask', 'station', 'product'],

    data: () => ({
        show: false,
        valid: false,
        showImage: false
    }),

    computed: {   
      currentStationOrderNumber() {
        return this.subtask.part.recipe.stations.find(x => x.id === this.subtask.current_station_id).pivot.order_number
      },

      previousStation() {
        return ((this.currentStationOrderNumber > 1) ? this.subtask.part.recipe.stations.find(x => x.pivot.order_number === this.currentStationOrderNumber - 1).name : '-');
      },

      nextStation() {
        return ((this.currentStationOrderNumber < this.subtask.part.recipe.stations[this.subtask.part.recipe.stations.length - 1].pivot.order_number) ? this.subtask.part.recipe.stations.find(x => x.pivot.order_number === this.currentStationOrderNumber + 1).name : '-');
      },

      isAffected() {
        return ((this.subtask.part.recipe.stations.find(x => x.id === this.station.id)) ? true : false)
      },

      wasHere() {
        if(this.subtask.part.recipe.stations.find(x => x.id === this.station.id)){
          return (this.subtask.part.recipe.stations.find(x => x.id === this.station.id).pivot.order_number < this.currentStationOrderNumber) 
        } 
        
        return false
      },

      isHere() {
        return (this.subtask.current_station_id === this.station.id) 
      },

      willBeHere() {
        if(this.subtask.part.recipe.stations.find(x => x.id === this.station.id)){
          return (this.subtask.part.recipe.stations.find(x => x.id === this.station.id).pivot.order_number > this.currentStationOrderNumber) 
        } 

        return false
      },

      color() {
        if(!this.isAffected) return "blue"
        if(this.isHere) return "white"
        if(this.wasHere) return "green"
        if(this.willBeHere) return "grey"
      },

      rules() {
        return  [
            v => (v >= 0) || 'negative',
            v => (+v >= +this.subtask.min_pieces) || 'Lower then minimum'
        ]
      },

      disabled() {
        return this.subtask.current_pieces < this.subtask.min_pieces
      },

      partHasComment(){
        if(this.subtask.part.comment == null || this.subtask.part.comment == "") return false
        else return true
      },

      partHasImage(){
        if(this.subtask.part.image == null || this.subtask.part.image == "") return false
        else return true
      },

      code(){
        if(this.subtask.part.is_unique) return this.subtask.part.code.split('#')[0]
        return this.subtask.part.code
      }

    },

    
    methods: {
      giveToNextStation() {
        this.$store.dispatch('loadingStatus', true)

            axios.post('/subtask/step',  {
              id: this.subtask.id,
              current_pieces: this.subtask.current_pieces,
              comment: this.subtask.comment,
              station_id: this.station.id
            })
                .then(response => {
                    this.subtask.current_station_id = response.data.current_station_id
                    this.$store.dispatch('loadingStatus', false)
                })
                .catch(error => {
                  alert(error.response.data)
                  this.$store.dispatch('loadingStatus', false)
                });
      },
    },
  }
</script>