<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="grey lighten-2" 
      slider-color="red"
      color="red"
      align-with-title
      >
      <v-tab>
        Feladat Kiadása
      </v-tab>
      <v-tab>
        Összes Feladat
      </v-tab>
      <v-tab>
        Prioritások módosítása
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" >
      <v-tab-item >
        <v-container fluid>
          <add-jobs @add="addTask" v-show="tasks.length == 0" :key="addJobKey"></add-jobs>
          <div class="red" v-for="error in errors">{{ error[0] }}</div>
          <v-form
            v-model="valid"
            ref="form2"
            lazy-validation
          >
            <add-jobs-table 
              v-for="(task, index) in tasks" 
              :key="task.key" 
              :task="task"
              :index="`${index}`" 
              @delete="deleteTask"
              class="pb-10 pt-10"
            ></add-jobs-table>
          </v-form>
          <v-row v-show="tasks.length == 1">
              <v-checkbox v-model="isPriority" label="TOP PRIO"></v-checkbox>
          </v-row>
          <v-row v-show="tasks.length == 1">
              <v-btn color="primary" @click="onSave">Mentés</v-btn>
              <v-btn @click="deleteTask(0)">Mégsem</v-btn> 
          </v-row>
          </v-container>
      </v-tab-item>
    <v-tab-item >
        <v-card flat>
          <tasks-table></tasks-table>
        </v-card>
      </v-tab-item>
    <v-tab-item >
        <v-card flat>
          <v-container fluid>
            <priority-table></priority-table>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>


<script>
class Task {
    constructor() {
      this.product = null
      this.sets = null
      this.min_sets = null
      this.status = "INACTIVE"
      this.comment = null
      this.subtasks = []
      this.key = (new Date()).getTime()
    }
}

class Subtask {
    constructor() {
      this.part = null
      this.pieces = null
      this.min_pieces = null
      this.from_storage_pieces = null
      this.needed = null
      this.comment = null
    }
}


  export default {
    data () {
      return {
        tab: null,
        tasks: [],
        newTask: null,
        newSubTask: null,
        parts: null,
        loading: false,
        isPriority: false,
        valid: true,
        errors: null,
        addJobKey: 0,
      }
    },

    methods: {
      addTask(...args) {

        this.newTask = new Task()
        this.newTask.product = args[0]
        this.newTask.sets = args[1]
        this.newTask.min_sets = args[2]

        if(this.parts = args[3]){
          this.parts.forEach(part => {
            this.newSubTask = new Subtask()
            this.newSubTask.part = part
          
            this.newTask.subtasks.push(this.newSubTask) 
          })      
        }

        this.tasks.push(this.newTask)
      },

      deleteTask(index) {
        this.$delete(this.tasks, index)
      },

       onSave () {
          if(this.$refs.form2.validate()){

            this.$store.dispatch('loadingStatus', true)

            axios.post('/planner/save',  {
              task: this.tasks[0],
              isPriority: this.isPriority
            })
              .then(response => {
                  this.$store.dispatch('loadingStatus', false)
                  alert('Sikeres ütemezés!')
                  this.tab = null,
                  this.tasks = [],
                  this.newTask = null,
                  this.newSubTask = null,
                  this.parts = null,
                  this.loading = false,
                  this.isPriority = false,
                  this.valid = true,
                  this.errors = null,
                  this.addJobKey++
              })
              .catch(error => {
                this.errors = error.response.data.errors
                this.$store.dispatch('loadingStatus', false)
              });
          }
      },

    }
  }
</script>