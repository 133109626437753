const state = {
    stations: [],
    userStations: []
};
const getters = {
    stations: state => {
        return state.stations
    },
    userStations: state => {
        return state.userStations
    },
};
const actions = {
    fetchStations({commit}) {
        axios.get('/station')
            .then(res => {
                {
                    commit('fetchStations', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    fetchUserStations({commit}) {
        axios.get('/user/stations')
            .then(res => {
                {
                    commit('fetchUserStations', res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
};
const mutations = {
    fetchStations(state, stations) {
        state.stations = stations
    },
    fetchUserStations(state, stations) {
        state.userStations = stations
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}