<template>
    <v-dialog v-model="dialog" max-width="200" @click:outside="$emit('close')">
        <v-img 
          :src="'/storage/' + this.image"
          max-width="200"
          class="white"
        ></v-img>
    </v-dialog>
</template>
<script>
  export default {
    data () {
      return { }
    },

    props: ['image', 'dialog'],
    
  }
</script>