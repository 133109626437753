<template>
  <v-card>
    <v-card-title>
      Feladatok
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="tasks" :search="search">
      <template v-slot:item.created_at="{ item }">
        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
      </template>
      <template v-slot:item.controls="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="grey"
          @click="onButtonClick(item)"
        >
          <v-icon dark>mdi-archive</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" max-width="1000px">
      <v-card v-if="task">
        <v-toolbar color="red">
          <v-row class="justify-between align-center">
            <v-col>Id: {{ this.task.id }} </v-col>
            <v-col>Product: {{ this.task.product.code }}</v-col>
            <v-col>Status: {{ this.task.status }}</v-col>
            <v-col>Sets: {{ this.task.sets }}</v-col>
            <v-col>Packed: {{ this.task.packed }}</v-col>
            <v-col>
              {{ new Date(this.task.created_at).toLocaleString() }}
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <task-history-table
            v-for="subtask in task.subtasks"
            :key="subtask.id"
            :subtask="subtask"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showDialog = false"
            >Bezárás</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "id", value: "id" },
        { text: "Termék", value: "product.code" },
        { text: "status", value: "status" },
        { text: "Priority", value: "priority" },
        { text: "sets", value: "sets" },
        { text: "packed", value: "packed" },
        { text: "comment", value: "comment" },
        { text: "created_at", value: "created_at" },
        { text: "", value: "controls", sortable: false },
      ],
      task: null,
      showDialog: false,
    };
  },

  mounted() {
    this.$store.dispatch("tasks/fetchAllTasks");

    window.Echo.channel("task-channel").listen(
      "UpdateTasksInProduction",
      (e) => {
        console.log(e);
        this.$store.dispatch("tasks/fetchAllTasks");
      }
    );
  },

  computed: {
    tasks() {
      return this.$store.getters["tasks/allTasks"];
    },
  },

  methods: {
    onButtonClick(item) {
      this.$store.dispatch("loadingStatus", true);

      axios
        .post("/task/history", {
          id: item.id,
        })
        .then((response) => {
          this.task = response.data;
          this.showDialog = true;
          this.$store.dispatch("loadingStatus", false);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$store.dispatch("loadingStatus", false);
        });
    },
  },
};
</script>