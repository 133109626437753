<template>
    <v-card>
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">Id</th>
                    <th class="text-left">Kód</th>
                    <th class="text-left">Szett</th>
                    <th class="text-left">Státusz</th>
                    <th class="text-left">Prioritás</th>
                    <th class="text-left">ÚJ Priortás</th>
                </tr>
            </thead>
                <draggable v-model="editTasks" tag="tbody">
                    <tr v-for="(task, index) in editTasks" :key="task.id">
                        <td>{{ task.id }}</td>
                        <td>{{ task.product.code }}</td>
                        <td>{{ task.sets }}</td>
                        <td>{{ task.status }}</td>
                        <td>{{ task.priority }}</td>
                        <td>{{ index+1 }}</td>
                    </tr>
                </draggable>
        </v-simple-table>
        <v-row>
            <v-col>
                <v-btn
                    color="primary"
                    @click="onSave"
                >
                    Mentés
                </v-btn>
            </v-col>
        </v-row>  
    </v-card>
</template>

<script>
    import draggable from "vuedraggable";
    export default {

    components: {
        draggable
    },

    data () {
        return {
            editTasks: null
        }
    },

    mounted() {
        this.$store.dispatch('tasks/fetchTasksInProduction')

        window.Echo.channel('task-channel')
            .listen('UpdateTasksInProduction', (e) => {
                console.log(e)
                this.$store.dispatch('tasks/fetchTasksInProduction')
            })
    },

    computed: {
      tasks() {
        return this.$store.getters['tasks/tasksInProduction'];
      },
    },

    watch: {
        tasks: function (val) {
            this.editTasks = val
        },
    },

    methods: {
        onSave(){
            this.$store.dispatch('loadingStatus', true)

            axios.post('/task/production/priority',  {
              tasks: this.editTasks,
            })
                .then(response => {
                    this.$store.dispatch('loadingStatus', false)
                    alert('Frissítve!')
                })
                .catch(error => {
                    alert(error.response.data)
                    this.$store.dispatch('loadingStatus', false)
                });
        },
    }


};
</script>
